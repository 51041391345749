import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Card from '../../../utilities/card/Card'
import CardHeader from '../../../utilities/card/CardHeader'
import Tab from '../../../utilities/tab/Tab'
import List from '../../../utilities/list/List'
import { searchFilter } from '../../../../helpers/SearchHelper'
import ToolsRenderer from '../../../utilities/list/ToolsRenderer'
import { apiPost, apiExportFile } from '../../../../helpers/Api'

import each from 'lodash/each'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import difference from 'lodash/difference'
import { UserStatus } from '../User'
import UserStat from '../stat/UserStat'
import { Dialog } from '@material-ui/core'
import { getExportFileNameDate, checkIfTimesInCorrectOrder } from '../../../../helpers/DateHelper'


export const UserIdentity = {
  0: 'trial',
  trial: 0,
  2: 'octopus',
  octotpus: 2,
  3: 'hkiarace',
  hkiarace: 3,
  4: 'aa_email',
  aa_email: 4,
  5: 'sasv',
  sasv: 5,
  50: 'sasvd',
  sasvd: 50,
  '-98': 'other',
  other: -98,
  '-99': 'expired',
  expired: -99
}

export const getUserIdentityTypeDisplay = (type) => {
  switch (type) {
    case 0:
      return 'Trial'
    case 2:
      return 'Octopus Card'
    case 3:
      return 'HKIARaCE'
    case 4:
      return 'AA Email'
    case 5:
      return 'SASV'
    case 50:
      return 'SASV-D'
    case -98:
      return 'Trial'
    case -99:
      return 'Expired'
    default:
      return ''
  }
}

let tabs = [
  {
    key: "all",
    name: "All",
    count: 0
  },
  {
    key: UserIdentity[UserIdentity.sasv],
    name: getUserIdentityTypeDisplay(UserIdentity.sasv),
    count: 0
  },
  // {
  //   key: UserIdentity[UserIdentity.sasvd],
  //   name: getUserIdentityTypeDisplay(UserIdentity.sasvd),
  //   count: 0
  // },
  // {
  //   key: UserIdentity[UserIdentity.trial],
  //   name: getUserIdentityTypeDisplay(UserIdentity.trial),
  //   count: 0
  // },
  // {
  //   key: UserIdentity[UserIdentity.octotpus],
  //   name: getUserIdentityTypeDisplay(UserIdentity.octotpus),
  //   count: 0
  // },
  {
    key: UserIdentity[UserIdentity.hkiarace],
    name: getUserIdentityTypeDisplay(UserIdentity.hkiarace),
    count: 0
  },
  {
    key: UserIdentity[UserIdentity.other],
    name: getUserIdentityTypeDisplay(UserIdentity.other),
    count: 0
  },
  // {
  //   key: UserIdentity[UserIdentity.aa_email],
  //   name: getUserIdentityTypeDisplay(UserIdentity.aa_email),
  //   count: 0
  // },
  {
    key: UserIdentity[UserIdentity.expired],
    name: getUserIdentityTypeDisplay(UserIdentity.expired),
    count: 0
  },
]

function UserList(props) {
  const {match, basePath, history} = props;

  const [originalData, setOriginalData] = useState([]);
  const [listKeyword, setListKeyword] = useState('');
  const [listFilter, setListFilter] = useState(null);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const updateTabs = () => {
    each(tabs, (tab, index) => {
      let count = 0;
      switch (tab.name) {
        case UserStatus.all:
          count = originalData.length;
          break;
        case 'Trial':
          count = originalData.filter(row => {
            if ('expired' in row.parsedIdentify) {
              return false
            }

            if (!('sasv' in row.parsedIdentify) && !('hkiarace' in row.parsedIdentify)) {
              return true
            }

            return false
          }).length
          break;
        default:
          count = originalData.filter((row) => {return row.parsedIdentify[tab.key] === true}).length;
          break;
      }
      tab.count = count;
    })
  }

  const [headerOptions, setHeaderOptions] = useState({
    enable: true,
    isSearch: true,
    searchableFields: ['name', 'user_name', 'store_name', 'email', 'mobile'],
    filters: [
      // {value: 'all', label: "All Roles"},
    ],
    onHeaderChange: (keyword, filter) => {
      setListKeyword(keyword);
      setListFilter(filter);
    }
  })

  const columns = [
    {
      headerName: "Login No.",
      field: "user_name",
      sort: "asc"
    },
    {
      headerName: "Username",
      field: "name"
    },
    {
      headerName: "Email",
      field: "email"
    },
    {
      headerName: "Mobile",
      field: "mobile"
    },
    // {
    //   headerName: "Company",
    //   field: "store_name"
    // },
    // {
    //   headerName: "Role",
    //   field: "role"
    // },
    // {
    //   headerName: "Verification Status",
    //   field: "verification_status"
    // },
    // {
    //   headerName: "Last Online",
    //   field: "last_online"
    // },
    // {
    //   headerName: "Stats",
    //   field: "stats",
    //   pinned: "right",
    //   width: 80,
    //   minWidth: 80,
    //   maxWidth: 80,
    //   sortable: false,
    //   cellRenderer: 'toolsRenderer',
    //   cellRendererParams: {
    //     onClickCallback: {
    //       stat: (row) => {
    //         viewUserStat(row)
    //       }
    //     },
    //     types: ["stat"]
    //   }
    // },
    {
      headerName: "Tools",
      field: "tools",
      pinned: "right",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      sortable: false,
      cellRenderer: 'toolsRenderer',
      cellRendererParams: {
        onClickCallback: {
          edit: (row) => {
            history.push(`${basePath}/edit/${row.user_id}`);
          },
          remove: (row) => {
            deleteUser(row);
          }
        },
        types: ["edit", "remove"]
      }
    }
  ]

  const [gridOptions, setGridOptions] = useState({
    columnDefs: columns,
    frameworkComponents: {
      toolsRenderer: ToolsRenderer
    }
  });

  const [rowData, setRowData] = useState([...originalData]);

  const search = () => {
    let filtered = [ ...originalData ];

    if (activeTab && activeTab.key !== 'all' && activeTab.key !== 'other') {

      // identity
      filtered = filtered.filter(data => {
        return data.parsedIdentify[activeTab.key] === true
      })
    }

    if (activeTab && activeTab.key === 'other') {
      filtered = filtered.filter(data => {
        if ('expired' in data.parsedIdentify) {
          return false
        }

        if (!('sasv' in data.parsedIdentify) && !('hkiarace' in data.parsedIdentify)) {
          return true
        }

        return false
      })
    }

    if (listKeyword) {
      filtered = searchFilter(filtered, headerOptions.searchableFields, listKeyword);
    }

    setRowData(filtered);
  }

  const checkUserIdentity = (user, parsedIdentify) => {
    each(user.aca_identity, (i) => {
      if (i.is_verified === 1) {
        if (i.expiry_date) {
          if (checkIfTimesInCorrectOrder(new Date(i.expiry_date * 1000), new Date())) {
            // expiry < now
            return;
          }
        }
        parsedIdentify[UserIdentity[i.identity_type]] = true
      }
    })
  }

  const getUserList = () => {
    let param = {
      department_code: "USER"
    }
    apiPost('/admin/user', param)
      .then((resp) => {
        let users = resp.data;

        each(users, (user) => {
          if (user.locked) {
            user.status = UserStatus.locked;
          } else {
            if (user.suspension_expired_at) {
              user.status = UserStatus.suspended;
            } else if (user.registration_state === 4) {
              user.status = UserStatus.registered;
            } else {
              user.status = UserStatus.not_yet_registered
            }
          }

          // verification
          user.parsedIdentify = {}
          checkUserIdentity(user, user.parsedIdentify)

          if (isEmpty(user.parsedIdentify)) {
            user.parsedIdentify[UserIdentity[-99]] = true
          }

        });

        setOriginalData(users);
      })
  }

  const deleteUser = (item) => {
    let param = {
      uid: item.user_id
    }
    apiPost('/admin/user/delete', param)
      .then((resp) => {
        history.push(match.url);
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const exportUserStat = () => {
    apiExportFile('/admin/hkaa_community/stat/user', {
      jobName: 'Export User Statistics',
      isDisplay: true,
      filename: `user_stat_${getExportFileNameDate()}`
    })
  }

  const [isUserStatModalOpen, setIsUserStatModalOpen] = useState(false);
  const [userStat, setUserStat] = useState({})

  const closeUserStat = () => {
    setUserStat({});
    setIsUserStatModalOpen(false);
  }

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    updateTabs()
    search()
  }, [originalData])

  useEffect(
    () => {
      search()
  }, [activeTab, listKeyword, listFilter]);

  return (
    <div className="list-container">
      <div className="header">
        <Card>
          <CardHeader>
            <div className="flex-align-center">
              <div className="title">Users</div>
              <div className="actions">
                <button 
                  className="button info"
                  onClick={exportUserStat}
                >
                  <a>Export Statistics</a>
                </button>
                <button 
                  className="button default"
                  onClick={() => history.push(`${basePath}/add`)}
                >
                  <a>Add New</a>
                </button>
              </div>
            </div>
            <Tab tabs={tabs} activeTab={activeTab} switchActiveTab={setActiveTab} />
          </CardHeader>
        </Card>
      </div>
      <div className="body">
        <List
          headerOptions={headerOptions}
          gridOptions={gridOptions}
          rowData={rowData}
        />
      </div>
      <Dialog 
        open={isUserStatModalOpen} 
        onClose={closeUserStat}
        fullWidth={true}
        maxWidth="lg"
      >
        <UserStat stat={userStat} handleClose={closeUserStat} />
      </Dialog>
    </div>
  )
}

UserList.propTypes = {

}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList)


import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import List from '../../../utilities/list/List';
import ToolsRenderer from '../../../utilities/list/ToolsRenderer';
import { searchFilter } from '../../../../helpers/SearchHelper';
import { apiPost } from '../../../../helpers/Api';
import { UserStatus } from '../../user/User';

import each from 'lodash/each'

function AdminList(props) {
  const { match, basePath, history } = props;

  const [originalData, setOriginalData] = useState([]);
  const [listKeyword, setListKeyword] = useState('');
  const [listFilter, setListFilter] = useState(null);

  const [headerOptions, setHeaderOptions] = useState({
    enable: true,
    isSearch: true,
    searchableFields: ['name', 'user_name', 'department_name'],
    filters: [
      // {value: 'all', label: "All Categories"},
    ],
    onHeaderChange: (keyword, filter) => {
      setListKeyword(keyword);
      setListFilter(filter);
    }
  })

  const columns = [
    { 
      headerName: "User Name", 
      field: "user_name",
      sort: "asc"
    },
    { 
      headerName: "Name", 
      field: "name"
    },
    { 
      headerName: "Department", 
      field: "department_name"
    },
    // {
    //   headerName: "Status",
    //   field: "status"
    // },
    { 
      headerName: "Tools", 
      field: "tools", 
      pinned: "right",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      sortable: false,
      cellRenderer: 'toolsRenderer',
      cellRendererParams: {
        onClickCallback: {
          edit: (row) => {
            history.push(`${basePath}/edit/${row.user_id}`);
          },
          remove: (row) => {
            deleteAdmin(row);
          }
        },
        types: ["remove"]
      }
    }
  ];

  const [gridOptions, setGridOptions] = useState({
    columnDefs: columns,
    frameworkComponents: {
      toolsRenderer: ToolsRenderer,
    }
  });

  const [rowData, setRowData] = useState([...originalData]);

  const search = () => {
    let filtered = [ ...originalData ];

    filtered = searchFilter(filtered, headerOptions.searchableFields, listKeyword);

    setRowData(filtered);
  }

  const getAdminList = () => {
    let param = {
      department_code: "ADMIN"
    }
    apiPost('/admin/user', param)
      .then((resp) => {
        let users = resp.data;

        each(users, (user) => {
          if (user.locked) {
            user.status = UserStatus.locked;
          } else {
            if (user.suspension_expired_at) {
              user.status = UserStatus.suspended;
            } else if (user.registration_state === 4) {
              user.status = UserStatus.registered;
            } else {
              user.status = UserStatus.not_yet_registered
            }
          }
        });

        setOriginalData(users);
      })
  }

  const deleteAdmin = (item) => {
    let param = {
      uid: item.user_id
    }
    apiPost('/admin/user/delete', param)
      .then((resp) => {
        history.push(match.url);
      })
      .catch((err) => {
        console.error(err);
      })
  }

  useEffect(() => {
    getAdminList()
  }, [])

  useEffect(() => {
    search()
  }, [originalData])

  useEffect(() => {
    search()
  }, [listKeyword, listFilter])

  return (
    <div className="list-container">
      <div className="header">
        <Card>
          <CardHeader>
            <div className="title">Admin</div>
            <div className="actions">
              <button 
                className="button default"
                onClick={() => history.push(`${basePath}/add`)}
              >
                <a>Add New</a>
              </button>
            </div>
          </CardHeader>
        </Card>
      </div>
      <div className="body">
        <List 
          headerOptions={headerOptions}
          gridOptions={gridOptions}
          rowData={rowData}
        />
      </div>
    </div>
  )
}

AdminList.propTypes = {

}

export default AdminList


import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@material-ui/core';
import CardHeader from '../card/CardHeader';
import Card from '../card/Card';

function ToolsRenderer(props) {
  const { types, data, onClickCallback, checkIsHidden, checkIsActive, field, isClickable=true} = props;

  const [popoverOpen, setPopoverOpen] = useState(false)
  const [popoverType, setPopoverType] = useState(null);
  const [popoverMsg, setPopoverMsg] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopOverClick = (event, type, msg) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true)
    setPopoverType(type);
    setPopoverMsg(msg);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverOpen(false)
    setPopoverType(null);
    setPopoverMsg(null);
  };

  const onTypeClick = (type, event) => {

    switch (type) {
      case "remove":
        handlePopOverClick(event, type, "Delete?");
        return;
      case "copy":
        handlePopOverClick(event, type, "Copy?")
        return;
    }

    triggerClickCallback(type);
  }

  const triggerClickCallback = (type) => {
    if (onClickCallback && onClickCallback[type]) {
      onClickCallback[type](data);
    }
  }


  return (
    <div>
      {
        types && types.map((type, index) => {
          let isActive = true
          if (checkIsActive) {
              isActive = checkIsActive(data)
          }

          if (data[`${type}Disabled`]) {
            return null
          }

          if (checkIsHidden && checkIsHidden(data)) {
            return null
          }

          let iconClass = null;
          switch (type) {
            case "edit":
              iconClass = "edit";
              break;
            case "remove":
              iconClass = "trash";
              break;
            case "stat":
              iconClass = "chart-line";
              break;
            case "export":
              iconClass = "download";
              break;
            case "copy":
              iconClass = "copy";
              break;
            case "view":
              iconClass = "search-plus";
              break;
            case "pin":
              iconClass = "thumbtack";
              break
            default:
              iconClass = "times";
              break;
          }

          return (
            <span className={`tool-button ${isClickable ? 'clickable hover-opacity': ''}`} key={index} onClick={(event) => onTypeClick(type, event)}>
              {field && !!data[field] && <FontAwesomeIcon fixedWidth icon={iconClass} style={isActive ? '' : {color: "#888888",}}/>}
              {!field && <FontAwesomeIcon fixedWidth icon={iconClass} style={isActive ? '' : {color: "#888888",}}/>}
            </span>
          )
        })
      }
      <Popover 
        id='remove-popover'
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Card>
          <CardHeader>
            <div className="title sub-title">
              {popoverMsg}
            </div>
            <div className="actions">
              <button className="button default" onClick={handleClose}>
                <FontAwesomeIcon icon="times" fixedWidth />
              </button>
              <button className="button primary" onClick={()=>triggerClickCallback(popoverType)}>
                <FontAwesomeIcon icon="check" fixedWidth />
              </button>
            </div>
          </CardHeader>
        </Card>
      </Popover>
    </div>
  )
}

ToolsRenderer.propTypes = {

}

export default ToolsRenderer


import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { NavLink } from 'react-router-dom'
import { Routes } from './Routes'

import "./PortalLinks.scss"
import { checkIfModuleIsEnabledByKey } from '../../helpers/AccountHelper'
import { FormattedMessage } from 'react-intl'


function PortalLinks(props) {
  const { isSideNavExtended, user } = props;

  let navContainerClass = ["nav-container"];
  if (isSideNavExtended) {
    navContainerClass.push("extend");
  }

  const checkIfModuleIsEnabled = (key) => {
    return checkIfModuleIsEnabledByKey(user, key)
  }

  return (
    <div className={navContainerClass.join(" ")}>
      {
        Routes.map((route, index) => {
          if (!checkIfModuleIsEnabled(route.moduleKey)) {
            return null;
          }
          return (
            <div className="nav-item" key={route.name}>
              <NavLink className="nav-link" activeClassName="active" key={route.name} to={route.path}>
                <div className="menu-icon-wrapper">
                  <div className={`menu-icon ${route.iconClass}`}></div>
                </div>
                <div className="name">
                  {
                    route.nameId &&
                    <FormattedMessage id={route.nameId} />
                  }
                  {
                    (!route.nameId && route.name) &&
                    route.name
                  }
                </div>
              </NavLink>
              {
                route.childrens &&
                route.childrens.map((childRoute, index) => {
                  if (!checkIfModuleIsEnabled(childRoute.moduleKey)) {
                    return null;
                  }
                  return (
                    <div className="nav-item child" key={childRoute.name}>
                      <NavLink className="nav-link" activeClassName="child-active" to={childRoute.path}>
                        <div className="menu-icon-wrapper">
                          <div className={`menu-icon ${childRoute.iconClass}`}></div>
                        </div>
                        <div className="name">
                          {
                            childRoute.nameId &&
                            <FormattedMessage id={childRoute.nameId} />
                          }
                          {
                            (!childRoute.nameId && childRoute.name) &&
                            childRoute.name
                          }
                        </div>
                      </NavLink>
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
    </div>
  )
}

PortalLinks.propTypes = {

}

const mapStateToProps = (state) => ({
  user: state.account.user
})

const mapDispatchToProps = {
  
}


export default connect(mapStateToProps, mapDispatchToProps)(PortalLinks)


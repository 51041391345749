import React from 'react'
import PropTypes from 'prop-types'

import { Route } from 'react-router-dom'
import { Routes } from './Routes'

import "./PortalRoutes.scss"

function PortalRoutes(props) {
  return (
    <div className="route-container">
      {
        Routes.map((route, index) => (
          <Route key={route.name} path={route.path} component={route.main}/>
        ))
      }
    </div>
  )
}

PortalRoutes.propTypes = {

}

export default PortalRoutes


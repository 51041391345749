// import React, { Component } from 'react'

import React, { useState } from 'react'
import './App.scss';

import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

import Login from './login/Login';
import Portal from './portal/Portal';

import { ToastContainer } from 'react-toastify';
import LoadingOverlay from './utilities/loading/LoadingOverlay';

import { IntlProvider } from 'react-intl';
import { i18nEn } from '../i18n/en';
import { langCht } from './utilities/form/MultiLangSelector';
import { i18nCht } from '../i18n/cht';
import { LOGIN_AUTH_TYPE } from '../helpers/Constants';

import each from 'lodash/each'
import last from 'lodash/last'
import ConfirmModal from './utilities/confirm/ConfirmModal';


const ProtectedRoute = ({isLoggedIn, ...props}) => isLoggedIn ? <Route {...props}/> : <Redirect to="/login"/>

function App(props) {
  const { isLoggedIn, locale } = props;

  // console.log(window.location.href)

  const handleExternalAuthMethod = () => {
    let authType = localStorage.getItem("auth_type")
    switch (authType) {
      case LOGIN_AUTH_TYPE.OAUTH:
        tryToExtractOauthToken()
        break;
      default:
        break;
    }
  }

  const tryToExtractOauthToken = () => {
    let originalHash = window.location.hash;
    let hrefParams = originalHash.split("#");
    let hrefParts = last(hrefParams).split("&");
    if (hrefParts.length === 0) {
        // there are no params, return
        return;
    }
    let idTokenRegEx = /id_token=(.*)/;
    let oAuthToken = null;
    each(hrefParts, (part) => {
        let match = idTokenRegEx.exec(part);
        if (match == null) {
            return false;
        }
        oAuthToken = match[1];
    });
    if (!oAuthToken) {
        // there is no id_token, return
        return;
    }
    localStorage.setItem("auth_token", oAuthToken);
  }

  handleExternalAuthMethod()

  let messages;

  switch (locale) {
    case langCht.key:
      messages = i18nCht;
      break;
    default:
      messages = i18nEn;
      break;
  }

  return (
    <IntlProvider locale={locale} key={locale} defaultLocale="en" messages={messages}>
      <div className="app">
        <Switch>
          <Route path="/login" component={Login}/>
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/portal" component={Portal}/>
          <Redirect to="/login"/>
        </Switch>
        <LoadingOverlay />
        <ConfirmModal />
        <ToastContainer 
          position="bottom-right"
          autoClose={3000} />
      </div>
    </IntlProvider>
  )
}

App.propTypes = {
  // isLoggedIn: Boolean
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.account.isLoggedIn,
  locale: state.language.language,
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(App)


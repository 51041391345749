import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import Tab from '../../../utilities/tab/Tab';
import List from '../../../utilities/list/List';
import ToolsRenderer from '../../../utilities/list/ToolsRenderer';
import { searchFilter } from '../../../../helpers/SearchHelper';
import { apiPost, apiExportFile } from '../../../../helpers/Api';

import each from 'lodash/each'
import find from 'lodash/find'
import union from 'lodash/union'
import DateRenderer from '../../../utilities/list/DateRenderer';
import PostStat from '../stat/PostStat';
import { postStatusTabs, postStatus, postStatusEnum, postStatusTrash, postStatusDraft, PostStatItems, PostTypes, PostTypeKeys } from '../../../../helpers/PostHelper';
import { Dialog } from '@material-ui/core';
import {
  getExportFileNameDate,
  getTimestampSecRangeForApi,
  getTimestampSecForApi,
  getChartMonthDateDisplayByTimestampSecond,
  getSimpleDateDisplayByTimestampSecond,
  getTimeStampStartOfMonthForApi
} from '../../../../helpers/DateHelper'
import { defaultLang } from '../../../utilities/form/MultiLangSelector';
import { subMonths, addWeeks } from 'date-fns';
import DateRangeInput from '../../../utilities/form/DateRangeInput';
import SelectInput from '../../../utilities/form/SelectInput';


function PostList(props) {
  const { basePath, history } = props;

  const [range, setRange] = useState([subMonths(new Date(), 6), addWeeks(new Date(), 2)])

  const [originalData, setOriginalData] = useState([]);
  const [listKeyword, setListKeyword] = useState('');
  const [listFilter, setListFilter] = useState(null);
  const [activeTab, setActiveTab] = useState(postStatusTabs[0]);

  const [categoryFilter, setCategoryFilter] = useState(null);
  const [allCategoryOptions, setAllCategoryOptions] = useState([]);

  const [typeFilter, setTypeFilter] = useState(PostTypes[0]);
  const [allTypeOptions, setAllTypeOptions] = useState(PostTypes)


  const updateTabs = () => {
    each(postStatusTabs, (tab, index) => {
      let count = 0;
      switch (tab.name) {
        case postStatus.all:
          count = originalData.filter((row) => {return row.status !== postStatusTrash.enum}).length;
          break;
        default:
          count = originalData.filter((row) => {return row.status === tab.enum}).length;
          break;
      }
      tab.count = count;
    })
  }

  const [headerOptions, setHeaderOptions] = useState({
    enable: true,
    isSearch: true,
    searchableFields: ['title', 'caption', 'html_content', 'author.name'],
    filters: [
      // {value: 'all', label: "All Categories"},
    ],
    onHeaderChange: (keyword, filter) => {
      setListKeyword(keyword);
      setListFilter(filter);
    }
  })

  const [categoryList, setCategoryList] = useState([])
  const getCategoryList = () => {
    let param = {

    }
    apiPost('/admin/post/category', param)
    .then((resp) => {
      setCategoryList(resp.data)
      resp.data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      })

      let categories = [{value: 'all', label: "All Categories"}];

      each(resp.data, (cat) => {
        categories.push({
          label: cat.name,
          value: cat.category_id
        })
      })

      // setHeaderOptions({
      //   ...headerOptions,
      //   ...{filters: categories}
      // })

      setAllCategoryOptions(categories)
      setCategoryFilter(categories[0])

      getPostList()
    })
  }
  useEffect(() => {
    search()
  }, [categoryList])


  const columns = [
    { 
      headerName: "Date", 
      field: "display_start",
      sort: 'desc',
      cellRenderer: 'dateRenderer',
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    { 
      headerName: "Title", 
      field: "title",
    },
    { 
      headerName: "Caption", 
      field: "caption",
    },
    { 
      headerName: "Author", 
      field: "author.name",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    { 
      headerName: "Category", 
      field: "categoryDisplay",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    { 
      headerName: "Status", 
      field: "statusDisplay",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      headerName: "Pin To Top",
      field: "is_pin_to_top",
      sortable: false,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      cellRenderer: 'toolsRenderer',
      cellRendererParams: {
        types: ["pin"],
        field: "is_pin_to_top",
        isClickable: false,
        checkIsActive: (row) => {
          if (row.is_pin_to_top) {

            const pinToTopStartInt = parseFloat(row.pin_to_top_start) * 1000;
            const pinToTopStart = Math.round(pinToTopStartInt);

            const pinToTopEndInt = parseFloat(row.pin_to_top_end) * 1000;
            const pinToTopEnd = Math.round(pinToTopEndInt);

            return Date.now() >= pinToTopStart
                && (Date.now() <= pinToTopEnd
                || row.pin_to_top_end === null)
          }

          return true

        }

      }
    },
    { 
      headerName: "Stats", 
      field: "stats",
      pinned: "right",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
      sortable: false,
      cellRenderer: 'toolsRenderer',
      cellRendererParams: {
        onClickCallback: {
          stat: (row) => {
            viewPostStat(row)
          }
        },
        types: ["stat"]
      }
    },
    { 
      headerName: "Tools", 
      field: "tools", 
      pinned: "right",
      width: 130,
      minWidth: 130,
      maxWidth: 130,
      sortable: false,
      cellRenderer: 'toolsRenderer',
      cellRendererParams: {
        onClickCallback: {
          edit: (row) => {
            history.push(`${basePath}/edit/${row.post_id}`);
          },
          remove: (row) => {
            deletePost(row);
          },
          copy: (row) => {
            copyPost(row);
          }
        },
        types: ["edit", "remove", "copy"]
      }
    }
  ];

  const [gridOptions, setGridOptions] = useState({
    columnDefs: columns,
    frameworkComponents: {
      toolsRenderer: ToolsRenderer,
      dateRenderer: DateRenderer
    }
  });

  const [rowData, setRowData] = useState([...originalData]);

  const search = () => {
    let filtered = [ ...originalData ];

    if (activeTab) {
      if (activeTab.key === 'all') {
        filtered = filtered.filter((data) => {
          return data.status !== postStatusTrash.enum;
        })
      } else {
        filtered = filtered.filter((data) => {
          return data.status === activeTab.enum;
        })
      }
    }

    if (categoryFilter && categoryFilter.value !== 'all') {
      filtered = filtered.filter((data) => {
        return data.category_ids && data.category_ids[0] === categoryFilter.value;
      })
    }

    if (typeFilter && typeFilter.value !== 'all') {
      if (typeFilter.value === PostTypeKeys.post) {
        filtered = filtered.filter((data) => {
          return !data.is_advertisement
        })
      } else if (typeFilter.value === PostTypeKeys.ad) {
        filtered = filtered.filter((data) => {
          return data.is_advertisement
        })
      }
    }

    filtered = searchFilter(filtered, headerOptions.searchableFields, listKeyword);

    each(filtered, (p) => {
      if (p.category_ids && p.category_ids.length > 0) {
        let target = find(categoryList, {category_id: p.category_ids[0]})
        if (target) {
          p.categoryDisplay = target.name
        }
      }
    })

    setRowData(filtered);
  }
  
  const getPostList = () => {
    let param = {
      display_start: getTimestampSecRangeForApi(range)
    }
    apiPost('/admin/post', param)
      .then((resp) => {
        let posts = resp.data;

        // TODO: mapping
        each(posts, (post) => {
          // post.like_count = post.emoji_counts[LikeEmojiString]
          post.statusDisplay = postStatusEnum[post.status]
        })

        setOriginalData(posts);
      })
  }

  const deletePost = (item) => {
    let param = {
      post_id: item.post_id
    }
    let url = '/admin/post/trash';
    if (item.status == postStatusTrash.enum) {
      url = '/admin/post/delete'
    }
    
    apiPost(url, param)
      .then((resp) => {
        getPostList();
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const copyPost = (item) => {
    let param = {
      post_id: item.post_id
    }

    apiPost('/admin/post', param)
      .then((resp) => {
        let post = resp.data;

        each(post._i18n, (lang, key) => {
          lang.title = `Copy of ${lang.title}`
        })
    
        let defaultLangContent = post._i18n[defaultLang.key];

        let thePost = {
          ...post,
          title: defaultLangContent.title,
          caption: defaultLangContent.caption,
          html_content: defaultLangContent.html_content,
          _i18n: post._i18n,
          display_start: new Date().getTime() / 1000,
          is_notify: 0,
          status: postStatusDraft.enum
        }

        apiPost('/admin/post/create', thePost)
          .then((resp) => {
            getPostList();
          })
          .catch((err) => {
            console.error(err)
          })
      })
  }

  const exportPostStat = () => {
    apiExportFile('/admin/post/stats/export', {
      display_start: getTimestampSecRangeForApi(range),
      jobName: 'Export Post Statistics',
      isDisplay: true,
      filename: `post_stat_${getExportFileNameDate()}`
    })
  }

  const [isPostStatModalOpen, setIsPostStatModalOpen] = useState(false);
  const [postStat, setPostStat] = useState({})
  const [postStatPost, setPostStatPost] = useState({})
  const [postStatUrl, setPostStatUrl] = useState([])
  const [postStatAllUrl, setPostStatAllUrl] = useState([])
  const viewPostStat = (post) => {
    setPostStatPost(post);
    let param = {
      post_id: post.post_id,
      time_interval: "month",
      start_time: getTimeStampStartOfMonthForApi(post.display_start),
      end_time: getTimestampSecForApi(new Date())
    }
    apiPost('/admin/post/stats', param)
      .then(({data}) => {
        each(data, (d) => {
          d.date = getChartMonthDateDisplayByTimestampSecond(d.start_time)
          each(PostStatItems, (item) => {
            d[item.label] = d[item.key] || 0
          })
        })

        setPostStat(data);
        getPostUrlStat(post);
      })
  }
  const getPostUrlStat = (post) => {
    let param = {
      referrer: post.share_url,
      time_interval: "month",
      start_time: parseFloat(post.display_start),
      end_time: getTimestampSecForApi(new Date())
    }
    apiPost('/admin/hkaa_community/stat/webview_log', param)
      .then(({data}) => {
        let urls = [];


        each(data, (d) => {
          d.date = getSimpleDateDisplayByTimestampSecond(d.start_time)
          if (param.time_interval === 'month') {
            d.date = getChartMonthDateDisplayByTimestampSecond(d.start_time)
          }

          each(d, (value, key) => {
            if (
              key !== 'start_time' && 
              key !== 'end_time' && 
              key !== 'date' &&
              !find(urls, {label: key})
            ) {
              urls = union(urls, [{
                label: key,
                value: key
              }]) 
            }
          })

          // d['No. of search'] = d.no_of_search
        })
        
        setPostStatUrl(data)
        setPostStatAllUrl(urls)

        setIsPostStatModalOpen(true);
      })
  }
  const closePostStat = () => {
    setPostStat({});
    setPostStatUrl([]);
    setIsPostStatModalOpen(false);
  }

  useEffect(() => {
    getCategoryList()
  }, [])

  useEffect(() => {
    updateTabs()
    search()
  }, [originalData])

  useEffect(() => {
    search()
  }, [activeTab, listKeyword, listFilter, categoryFilter, typeFilter])

  useEffect(() => {
    if (range.length !== 2) {
      return;
    }
    getPostList()
  }, [range])

  return (
    <div className="list-container">
      <div className="header">
        <Card>
          <CardHeader>
            <div className="title">Posts</div>
            <div className="actions">
              <button 
                className="button info"
                onClick={exportPostStat}
              >
                <a>Export</a>
              </button>
              <button 
                className="button default"
                onClick={() => history.push(`${basePath}/add`)}
              >
                <a>Add New</a>
              </button>
            </div>
          </CardHeader>
        </Card>
        <br></br>
        <Card>
          <CardHeader>
            {/* <div className="flex-align-center">
              Date
            </div>
            <DateRangeInput 
              value={range}
              onChange={setRange}
            /> */}
            <div className="filter-row full-width">
              <div className="width-33">
                <div>
                  Date
                </div>
                <div>
                  <DateRangeInput 
                    value={range}
                    onChange={setRange}
                    className="text-align-left border"
                  />
                </div>
              </div>
              <div className="width-33">
                <div>
                  Category
                </div>
                <div>
                  <SelectInput 
                    options={allCategoryOptions}
                    value={categoryFilter}
                    onChange={setCategoryFilter}
                  />
                </div>
              </div>
              <div className="width-33">
                <div>
                  Type
                </div>
                <div>
                  <SelectInput 
                    options={allTypeOptions}
                    value={typeFilter}
                    onChange={setTypeFilter}
                  />
                </div>
              </div>
            </div>
          </CardHeader>

          <CardHeader>
            <div className="flex-align-center">
              <div className="">Status</div>
            </div>
            <Tab tabs={postStatusTabs} activeTab={activeTab} switchActiveTab={setActiveTab} />
          </CardHeader>
        </Card>
      </div>
      <div className="body">
        <List 
          headerOptions={headerOptions}
          gridOptions={gridOptions}
          rowData={rowData}
        />
      </div>
      <Dialog
        open={isPostStatModalOpen}
        onClose={closePostStat}
        fullWidth={true}
        maxWidth="lg"
        scroll="paper"
      >
        <PostStat 
          post={postStatPost} 
          stat={postStat} 
          urlStat={postStatUrl}
          urls={postStatAllUrl}
          handleClose={closePostStat} 
        />
      </Dialog>
    </div>
  )
}

PostList.propTypes = {

}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(PostList)

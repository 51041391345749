import React, { useState, useEffect, createRef } from 'react'
import PropTypes from 'prop-types'

import store from '../../redux/store';
import { updateIsLoggedIn, updateUser } from '../../redux/actions';

import "./Login.scss"
import { TargetDomain, LOGIN_AUTH_TYPE } from '../../helpers/Constants';
import { apiPost } from '../../helpers/Api';
import { FormattedMessage } from 'react-intl';

import filter from 'lodash/filter'
import find from 'lodash/find'
import difference from 'lodash/difference'
import { toast } from '../utilities/Notification/CustomToast';


function Login(props) {
  const { history } = props;


  const goToPortal = () => {
    store.dispatch(updateIsLoggedIn({isLoggedIn: true}));
    history.push('/portal');
  }

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const usernameRef = createRef()
  const passwordRef = createRef()

  const onKeyDown = (event) => {
    if (event.keyCode !== 13) {
      return
    }

    if (username && password) {
      login()
      return
    }

    if (username) {
      passwordRef.current.focus()
      return
    }
  }

  const loginSuccess = (data) => {
    clearAuthStorage()
    store.dispatch(updateUser({user: data}));
    goToPortal();
  }

  const loginFailure = (err) => {
    clearAuthStorage()
    if (err.response.status === 400) {
      toast.error('User not found')
      return
    }
    if (err.response.status === 401) {
      toast.error('Incorrect Username or Password')
      return
    }
    if (err.response.status === 403) {
      toast.error('Your account has been locked')
      return
    }
  }

  const login = () => {
    if (!username || !password) {
      toast.warn('Please enter username and password')
      return false
    }

    let param = {
      uid: username,
      hashed_password: password,
      domain: TargetDomain
    }

    apiPost('/admin/login', param)
      .then((resp) => {
        loginSuccess(resp.data)
      })
      .catch((err) => {
        loginFailure(err)
      })
  }

  const [isAllowTeamNoteLogin, setIsAllowTeamNoteLogin] = useState(true)
  const [isDisplayTeamNoteLogin, setIsDisplayTeamNoteLogin] = useState(false)
  const [loginMethods, setLoginMethods] = useState([])

  const getLoginMethods = () => {
    apiPost('/login/methods', {
      company_domain: TargetDomain,
      device_type: "portal"
    })
      .then((resp) => {
        let data = resp.data;

        let tnMethods = filter(data, (m) => {
          return m.auth_type === LOGIN_AUTH_TYPE.TEAMNOTE
        })
        setIsAllowTeamNoteLogin(tnMethods.length > 0)
        
        setLoginMethods(difference(data, tnMethods))
      })
  }

  const onLoginMethodClick = (method) => {
    localStorage.setItem('auth_type', method.auth_type)
    localStorage.setItem('auth_name', method.auth_name)

    switch (method.auth_type) {
      case LOGIN_AUTH_TYPE.OAUTH:
        handleOauthMethod(method);
        break;
      case LOGIN_AUTH_TYPE.SAML:
        handleSamlMethod(method);
        break;
    }
  }

  const handleOauthMethod = (method) => {
    let endpoint = method.config.auth_endpoint;

    let url = endpoint.url;

    url += `?response_type=${endpoint.response_type}`
    url += `&client_id=${endpoint.client_id}`
    url += `&scope=${endpoint.scope}`
    url += `&nonce=${endpoint.nonce}`
    url += `&redirect_uri=${window.location.origin + window.location.pathname}`

    window.location.replace(url)
  }

  const handleSamlMethod = (method) => {
    apiPost('/login/methods', {
      company_domain: TargetDomain,
      device_type: "portal"
    })
      .then(({data}) => {
        let targetMethod = find(data, {auth_type: method.auth_type, auth_name: method.auth_name})
        if (targetMethod) {
          let endpoint = targetMethod.config.auth_endpoint;
      
          let tempForm = document.createElement('form');
          tempForm.setAttribute("method", "post")
          tempForm.setAttribute("action", endpoint.url)
          tempForm.setAttribute("target", "_self")
      
          let samlRequestField = document.createElement("input");
          samlRequestField.setAttribute("type", "text");
          samlRequestField.setAttribute("name", "SAMLRequest");
          samlRequestField.setAttribute("value", endpoint.SAMLRequest);
      
          let relayStateField = document.createElement("input");
          relayStateField.setAttribute("type", "text");
          relayStateField.setAttribute("name", "RelayState");
          relayStateField.setAttribute("value", window.location.pathname);
      
          tempForm.appendChild(samlRequestField);
          tempForm.appendChild(relayStateField);
      
          document.body.appendChild(tempForm);
      
          tempForm.submit();
        }
      })
  }

  const tryToLoginWithExternalAuth = () => {
    let authType = localStorage.getItem("auth_type")
    switch (authType) {
      case LOGIN_AUTH_TYPE.OAUTH:
        loginWithOauthToken()
        break;
      case LOGIN_AUTH_TYPE.SAML:
        loginWithSaml();
        break;
    }
  }

  const clearAuthStorage = () => {
    localStorage.removeItem("auth_type")
    localStorage.removeItem("auth_name")
    localStorage.removeItem("auth_token")
  }

  const loginWithOauthToken = () => {
    let oauthToken = localStorage.getItem("auth_token")
    if (!oauthToken) {
      clearAuthStorage()
      return;
    }

    apiPost('/admin/login/oauth', {
      company_domain: TargetDomain,
      auth_name: localStorage.getItem("auth_name"),
      id_token: oauthToken
    })
      .then((resp) => {
        loginSuccess(resp.data)
      })
      .catch((err) => {
        loginFailure(err)
      })
  }

  const loginWithSaml = () => {
    apiPost('/admin/login/saml', {
      company_domain: TargetDomain,
      auth_name: localStorage.getItem("auth_name"),
    })
      .then((resp) => {
        loginSuccess(resp.data)
      })
      .catch((err) => {
        loginFailure(err)
      })
  }

  useEffect(() => {
    getLoginMethods()
    tryToLoginWithExternalAuth()
  }, [])

  return (
    <div className="login-container">
      <div className="login-left">
        <div className="logo-wrapper box-shadow">
          <div className="logo background-image"></div>
        </div>
        {
          loginMethods.map((m, index) => (
            <div className="login-method" key={m.auth_type}>
              <button className="button primary login-button" onClick={()=>onLoginMethodClick(m)}>
                <span>Login ({m.auth_name})</span>
                <span>-&gt;</span>
              </button>
            </div>
          ))
        }

        {
          (isAllowTeamNoteLogin && !isDisplayTeamNoteLogin) &&
          <div className="login-method">
            <div className="tip clickable hover-opacity text-underline" onClick={()=>setIsDisplayTeamNoteLogin(true)}>
              Login with local account
            </div>
          </div>
        }
        {
          isDisplayTeamNoteLogin &&
          <div className="login-method">
            <div className="input-container">
              <input 
                id="username"
                ref={usernameRef}
                type="text" 
                className="form-input" 
                placeholder="Username" 
                value={username} 
                onChange={(e) => setUsername(e.target.value)} 
                onKeyDown={onKeyDown}
              />
            </div>
            <div className="input-container">
              <input 
                id="password"
                ref={passwordRef}
                type="password" 
                className="form-input" 
                placeholder="Password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                onKeyDown={onKeyDown}
              />
            </div>
            <div className="input-container">
              <button className="button primary login-button" onClick={login}>
                <div>
                  <FormattedMessage id='login.login' />
                </div>
              </button>
            </div>
          </div>
        }

        <div className="description">
          <p>
            Welcome to the HKG Community Portal. 
            This web site is accessible only to authorized users of HKG Community. By continuing to access this website, you represent that you are an authorized user and agree to have all your activities with this website be logged. Otherwise please close the browser now.
          </p>
          <p>
            <b>Reminder:</b><br/>
            (1) Don't use this service in public area.<br/>
            (2) Don't disclose any account information.<br/>
            (3) Don't close your web browser directly. Please use the logoff icon to end the service and then close the browser.
          </p>
          <p>
            <b>Important: Remember to Log Off</b> <br/>
            Log off after you finish. To complete the log off process and prevent other users from opening your mailbox, you must close all browser windows and exit the browser application.
          </p>
        </div>

      </div>

      <div className="login-right">
        <div className="login-image background-image"></div>
      </div>
    </div>
  )
}

Login.propTypes = {

}

export default Login


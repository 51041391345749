import React, {useState, useEffect, useRef} from 'react'
import { connect } from 'react-redux'

import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import List from '../../../utilities/list/List';
import ToolsRenderer from '../../../utilities/list/ToolsRenderer';
import { searchFilter } from '../../../../helpers/SearchHelper';
import { apiPost } from '../../../../helpers/Api';

import DateRenderer from '../../../utilities/list/DateRenderer';
import moment from 'moment';
import each from "lodash/each";
import RefreshHeaderComponent from "./RefreshHeaderComponent";

function NotificationList(props) {
    const {basePath, history} = props;

    const [originalData, setOriginalData] = useState([]);
    const [listKeyword, setListKeyword] = useState('');
    // const [listFilter, setListFilter] = useState(null);
    const [headerOptions, setHeaderOptions] = useState({
        enable: true,
        isSearch: true,
        searchableFields: ['content_ch', 'content_zh', 'create_date', 'progress'],
        filters: [
            // {value: 'all', label: "All Categories"},
        ],
        onHeaderChange: (keyword, filter) => {
            setListKeyword(keyword);
            // setListFilter(filter);
        }
    })

    const columns = [
        {
            headerName: "Content ZH",
            field: "content_zh"
        },
        {
            headerName: "Content EN",
            field: "content_en"
        },
        {
            headerName: "Sent Date",
            field: "create_date"
        },
        {
            headerName: "Progress",
            headerComponent: 'RefreshHeaderComponent',
            headerComponentParams: {
                setOriginalData: setOriginalData,
            },
            field: "progress"
        }
    ];

    const [gridOptions, setGridOptions] = useState({
        columnDefs: columns,
        frameworkComponents: {
            toolsRenderer: ToolsRenderer,
            dateRenderer: DateRenderer,
            RefreshHeaderComponent: RefreshHeaderComponent,
        },
    });

    const [rowData, setRowData] = useState([...originalData]);

    const search = () => {
        let filtered = [...originalData];

        filtered = searchFilter(filtered, headerOptions.searchableFields, listKeyword);

        setRowData(filtered);
    }

    const getNotificationList = () => {
        apiPost('/admin/api-notifications',)
            .then((resp) => {
                let notifications = resp.data;

                each(notifications, (n) => {
                    // change progress to 0/0 if progress_total or progress_finished is not set
                    if (!n.progress_total | !n.progress_finished) {
                        n.progress = '0/0';
                    } else {
                        n.progress = n.progress_finished + '/' + n.progress_total;
                    }

                    // change unix timestamp to readable date
                    n.create_date = moment.unix(n.create_date).format('YYYY-MM-DD HH:mm:ss');
                })

                setOriginalData(notifications);
            })
    }

    useEffect(() => {
        search()
    }, [originalData])

    useEffect(() => {
        search()
    }, [listKeyword])

    useEffect(() => {
        getNotificationList()
    }, [])

    return (
        <div className="list-container">
            <div className="header">
                <Card>
                    <CardHeader>
                        <div className="title">Notifications</div>
                    </CardHeader>
                </Card>
                <br></br>
            </div>
            <div className="body">
                <List
                    headerOptions={headerOptions}
                    gridOptions={gridOptions}
                    rowData={rowData}
                />
            </div>
        </div>
    )
}

NotificationList.propTypes = {

}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList)

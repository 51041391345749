import React, {useState} from 'react';
import {apiPost} from "../../../../helpers/Api";
import each from "lodash/each";
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default ({ displayName, setOriginalData }) => {
    const[isLoading, setIsLoading] = useState(false);

    const getNotificationList = () => {
        setIsLoading(true);
        apiPost('/admin/api-notifications',)
            .then((resp) => {
                let notifications = resp.data;

                each(notifications, (n) => {
                    // change progress to 0/0 if progress_total or progress_finished is not set
                    if (!n.progress_total | !n.progress_finished) {
                        n.progress = '0/0';
                    } else {
                        n.progress = n.progress_finished + '/' + n.progress_total;
                    }

                    // change unix timestamp to readable date
                    n.create_date = moment.unix(n.create_date).format('YYYY-MM-DD HH:mm:ss');
                })

                setOriginalData(notifications);
                setIsLoading(false);
            })
    }

    return (
        <div>
            <span>{displayName}</span>
            <FontAwesomeIcon icon="redo"
                             style={{color: "#888888", padding: "0px 5px"}}
                             onClick={getNotificationList}
                             spin={isLoading}/>
        </div>
    );
};

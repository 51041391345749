import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Switch, Route, Redirect } from 'react-router-dom'
import NotificationList from './list/NotificationList';

import "./Notification.scss"

function Notification(props) {
    const { match } = props;

    return (
        <div className="page-container">
            <Switch>
                <Route path={`${match.url}/list`}
                       render={props => <NotificationList {...props} basePath={match.url}></NotificationList>}/>
                <Redirect to={`${match.url}/list`}/>
            </Switch>
        </div>
    )
}

Notification.propTypes = {

}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)

import format from 'date-fns/format'
import isBefore from 'date-fns/isBefore'
import differenceInDays from 'date-fns/differenceInDays'
import { startOfMonth } from 'date-fns';
import { langCht } from '../components/utilities/form/MultiLangSelector';

export const getDateByFormat = (timestamp, dateFormat) => {
  let timestampF = parseFloat(timestamp);
  if (!timestampF || timestampF <= 0) {
    return null;
  }
  let date = new Date(timestampF * 1000);
  return format(date, dateFormat)
}

export const getDateByFormatAndDateObj = (date, dateFormat) => {
  if (!date || !dateFormat) {
    return null;
  }
  return format(date, dateFormat);
}

export const getFullDateDisplayByTimestampSecond = (timestamp) => {
  if (!timestamp) {
    return '';
  }
  return getDateByFormat(timestamp, 'yyyy-MM-dd HH:mm')
}

export const getSimpleDateDisplayByTimestampSecond = (timestamp) => {
  if (!timestamp) {
    return ''
  }
  return getDateByFormat(timestamp, 'yyyy-MM-dd')
}

export const getChartMonthDateDisplayByTimestampSecond = (timestamp) => {
  if (!timestamp) {
    return '';
  }
  return getDateByFormat(timestamp, 'MMM yyyy')
}

export const checkIfTimesInCorrectOrder = (before, after) => {
  return isBefore(before, after)
}

export const getExportFileNameDate = () => {
  return format(new Date(), 'yyyyMMdd_HHmm');
}

export const getExportFileNameDateRangeBySec = (start, end) => {
  start = new Date(start * 1000)
  end = new Date(end * 1000)
  return `${format(start, 'yyyyMMdd')}-${format(end, 'yyyyMMdd')}`
}

export const getPromotionNumberOfDaysLeft = (timestamp, langKey) => {
  let daysLeft = differenceInDays(new Date(timestamp * 1000), new Date()) + 2
  if (daysLeft < 0) {
    switch (langKey) {
      case langCht.key:
        return '已過期'
      default:
        return 'Expired'
    }
  }

  switch (langKey) {
    case langCht.key:
      return `最後${daysLeft}日`
    default:
      return daysLeft + (daysLeft === 1 ? ' day left' : ' days left')
  }
}

export const getTimestampSecForApi = (date) => {
  return date.getTime() / 1000
}

export const getTimeStampStartOfMonthForApi = (timestamp) => {
  if (!timestamp) {
    return '';
  }

  let startOfMonthDate = startOfMonth(parseFloat(timestamp) * 1000);
  return getTimestampSecForApi(startOfMonthDate)
}

export const getTimestampSecRangeForApi = (dates) => {
  let start = dates[0];
  let end = dates[1]

  if (!start || !end) {
    return null
  }

  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 0);
  return [start / 1000, end / 1000]
}

export const getExportFileNameDisplay = () => {
  return getDateByFormatAndDateObj(new Date(), 'yyyy-MM-dd-HHmm');
}